/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from "../../../_metronic/helpers";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {string} from "yup";
import AdminModal from "./AdminModal";
import {useAuth} from "../auth";

interface adminData {
    name: string
    show: boolean
    address: string
    date: string
    id: string
}

const AdminPage: React.FC = () => {

    const [data, setData] = useState<Array<adminData>>()
    const [show, setShow] = useState(false)
    const [id, setId] = useState<string>("")
    useEffect(() => {
        getMemories()
    }, []);

    const {currentUser, logout} = useAuth()


    const getMemories = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/data/all`)
            .then(res => {
                setData(res.data)
            })
    }
    const handleModal = (e: any) => {
        setId(e.target.id)
        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
        getMemories()
    };
    const handleShow = () => setShow(true);

    return (
        <div className={`card mb-5 mb-xl-8`}>
            <AdminModal key={Math.random()} show={show} handleClose={handleClose} id={id}/>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Onaylanacak Anılar</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Admin Paneli</span>
                </h3>
                <div className='menu-item px-5'>
                    <a onClick={logout} className='btn btn-primary px-5'>
                        Çıkış Yap
                    </a>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                        <tr className='fw-bold text-muted'>
                            <th className='w-25px'>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='1'
                                        data-kt-check='true'
                                        data-kt-check-target='.widget-9-check'
                                    />
                                </div>
                            </th>
                            <th className='min-w-100px'>Hatıra Adı</th>
                            <th className='min-w-100px'>Adresi</th>
                            <th className='min-w-100px'>Onay Durumu</th>
                            <th className='min-w-100px'>Tarih</th>
                            <th className='min-w-100px text-end'>İşlemler</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {(data && data.length > 0) ? (
                            data.map(item => {
                                return (
                                    <tr>
                                        <td>
                                            <div
                                                className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input className='form-check-input widget-9-check' type='checkbox'
                                                       value='1'/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex justify-content-start flex-column'>
                                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                                            {item.name}
                                        </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {item.address}
                                            </span>
                                        </td>
                                        <td className='text-center'>
                                            <div className='d-flex flex-column w-100 me-2'>
                                                <div className='d-flex flex-stack mb-2'>
                                                    <span
                                                        className={`badge badge-${item.show ? 'success' : 'danger'} me-2 fs-7 fw-semibold`}>
                                                        {item.show ? 'Onaylandı' : 'Onaylanmadı'}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                {item.date}
                                            </span>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                <button
                                                    className='btn btn-warning btn-active-color-primary btn-sm'
                                                    id={item.id}
                                                    onClick={handleModal}
                                                >
                                                    Düzenle
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        ) : <Spinner animation="grow"/>}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
        </div>
    )
}

export {AdminPage}
