const HelpDrawerStyle = {
    cardContainer: {
        backgroundColor: '#FFFAF5',
    },
    locationContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    charField: {
        border: '1px solid #CDC4C2',
        borderRadius: '8px',
        backgroundColor: 'white',
        fontFamily: 'source-sans-pro-regular',
        color: '#503F40'
    },
    textCounter: {
        display: 'flex',
        justifyContent: 'right',
    },
    cardHeader: {

    }
}

const StaticLocationStyle = {
    addressText: {
        fontWeight: 400,
        fontSize: 16,
        color: '#A09291'
    }
}

export const styles = {
    helpDrawerStyle: HelpDrawerStyle,
    StaticLocationStyle: StaticLocationStyle
}