import React, {FC} from 'react'

const ToggleHelpDrawer: FC = () => (
  <button
    id='kt_help_toggle'
    title='HaritaHatırası'
    data-bs-toggle='tooltip'
    data-bs-placement='left'
    data-bs-dismiss='click'
    data-bs-trigger='hover'
  >
  </button>
)

export {ToggleHelpDrawer}
