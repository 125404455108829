/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";
import { CustomButton } from "../custom-button/custom-buttom";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import "../../../assets/style/styles";
import { styles } from "../../../assets/style/styles";
import { MemoryCard } from "../memory-card/memoryCard";
import axios from "axios";


const StaticLocation = ({
    modalData,
    userData,
    setType,
    address,
    setUserData,
    memories,
    setMemories,
    setMemoryId,
    setPreviousType,
    previousType,
}) => {
    useEffect(() => {
        if (modalData){
            axios.get(`${process.env.REACT_APP_API_URL}/data/memories?typ=staticLocation&parent_id=${modalData.id}`).then(res => {
                setMemories(res?.data);
                // res.data.map((item) => {
                // })
            }).catch(err => console.log(err))
        }
    }, [modalData]);
    return (

        <div className='card shadow-none rounded-0 w-100' style={styles.helpDrawerStyle.cardContainer}>
            {/* Header */}
            <div className='custom-card-header' id='kt_help_header_static' style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'row', }}>
                <img src={toAbsoluteUrl('/media/icons/Pin_Castle.svg')} />
                <div style={{flexDirection: 'column'}}>
                    <a className='header-name-text mt-5 header-address-text'>{modalData?.name}</a>
                    <a style={styles.StaticLocationStyle.addressText}>{address}</a>
                </div>
                </div>
                <div className='card-toolbar mt-2'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon explore-btn-dismiss mt-3'
                        id='kt_help_close'
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2'/>
                    </button>
                </div>
            </div>
            {/* Body */}
            {modalData && (
                // <>
                    <div className="card-body scroll" style={{height: '92%'}}>
                        <div className='col-12 mt-4 justify-content-center'>
                            <img width={'100%'} src={modalData.photo[0]} style={{borderRadius: '8px'}} alt=""/>
                        </div>
                        <div className='col-12 mt-4 mb-5 justify-content-center'>
                            <p dangerouslySetInnerHTML={{__html: modalData?.description}} className="description-text" style={{width: '100%', }}></p>
                        </div>
                        <div className="title-header">
                            <p className="title-header-text tag-header-text">Hatıralar</p>
                        </div>
                        <div className='mt-5 memory-wrapper' style={{height:'45%'}}>
                            {memories.length > 0
                                ? (
                                    memories.map(item => {
                                        return(
                                            <MemoryCard
                                                key={item.id}
                                                memory={item}
                                                setMemoryId={setMemoryId}
                                                setType={setType}
                                                setPreviousType={setPreviousType}
                                            />
                                        )
                                    })
                                )
                                :
                                    <p className="description-text">Henüz eklenmiş bir hatıra bulunmuyor.</p>
                            }
                        </div>
                    </div>

                // </>
            )}
            <div className="" style={{marginInline: 26}}>
                <CustomButton
                    buttonTitle='Hatıra ekle'
                    onClick={() => {
                        setType((previous) => {
                            setPreviousType(previous);
                        });
                        setType('newLocation')
                        setUserData({...userData, address: modalData.address, typ: 'staticLocation', parent_id: modalData.id})
                    }}
                />
            </div>

        </div>
    )
}

export {StaticLocation}