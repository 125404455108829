import React from "react";
import { Search } from "react-bootstrap-icons";
import '../../../assets/style/styles.css'


const SearchBar = ({
    searchText,
    setSearchText,
    isPhone
}: any) => {
    const [search, setSearch] = React.useState('')

    return(
        <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', minWidth: isPhone ? '18vw' : '38vw'}}>
            <div className="mt-2" style={{minWidth: isPhone ? '12vw' : '28vw', border: '1px solid #CDC4C2', height: 44, borderRadius: 6, backgroundColor: '#FFFFFF', display: 'flex'}}>
                <div style={{display: 'flex', alignItems:'center', marginLeft: 8}}
                     onClick={event => setSearchText(search)}>
                    <Search size={'24'}/>
                </div>
                <input
                    id={isPhone ? "search-input-1" : "search-input"}
                    type='text'
                    style={{marginLeft: 2, border: 'none', minWidth: isPhone ? '2%' : '80%', textIndent: 12}}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            // @ts-ignore
                            setSearchText(search)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export {SearchBar}
