import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";


type Props = {
    show: boolean
    handleClose: any
    id: string
}

interface adminData {
    id: string,
    name: string,
    address: string,
    detail: string,
    date: string,
    path: [string],
    latitude: number,
    longitude: number,
}

const AdminModal: React.FC<Props> = ({show, handleClose, id}) => {
    const [data, setData] = useState<adminData>()

    const handleAccept = () => {
        if (id) {
            axios.patch(`${process.env.REACT_APP_API_URL}/data/memories/${id}?show=${true}`)
                .then(res => {
                    handleClose()
                }).catch(err => {
                handleClose()
            })
        }
    }

    const handleNotAccept = () => {
        if (id) {
            axios.patch(`${process.env.REACT_APP_API_URL}/data/memories/${id}?show=${false}`)
                .then(res => {
                    handleClose()
                }).catch(err => {
                handleClose()
            })
        }
    }

    useEffect(() => {
        getMemories()
    }, [id]);

    const getMemories = () => {
        if (id) {
            axios.get(`${process.env.REACT_APP_API_URL}/data/memories/${id}`)
                .then(res => {
                    setData(res.data)
                })
        }
    }

    return (
        <>
            {
                data &&
                <Modal size="xl" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{data.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Header>
                        <Modal.Title>{data?.address}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {data.detail}
                        <div>
                            Latitude {data.latitude}
                        </div>
                        <div>
                            Longitude {data.longitude}
                        </div>
                    </Modal.Body>
                    <Modal.Body>
                        {
                            data.path.map((item, index) => {
                                return (
                                    <img width={data.path.length > 0 ? `${100 / data.path.length}%`: '100%'} src={item} alt=""/>
                                )
                            })
                        }
                    </Modal.Body>
                    <Modal.Footer >
                        <Button variant="success" onClick={handleAccept}>
                            Onayla
                        </Button>
                        <Button variant="danger" onClick={handleNotAccept}>
                            Onaylama
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Kapat
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default AdminModal;