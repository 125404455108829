/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {Navbar} from './Navbar'
import $ from 'jquery';
import { styles } from '../../../assets/style/styles';
import '../../../assets/style/styles.css';
import { SearchBar } from '../search-bar/searchBar';
import { Search } from '../../../partials'


export function HeaderWrapper({
  setType,
  searchText,
  setSearchText,
}: any) {
  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <div id='kt_app_header' className='app-header top-bar'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary h-35px'
                id='kt_app_sidebar_mobile_toggle'
                style={{width: '93vw', justifyContent: 'space-between'}}
              >
                <img width={120} src={toAbsoluteUrl('/media/icons/sidebar_icons/Logo.svg')} alt=""/>
                <SearchBar
                  searchText={searchText}
                  setSearchText={setSearchText}
                  isPhone={true}
                />
                <div
                  onClick={() => {
                    setType('tag');
                    $("#kt_help_toggle").click();
                  }}
                >
                  <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
                </div>
              </div>
            </div>
          </>
        )}

        {/* {!config.app.sidebar?.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/dashboard'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )} */}

        <div
          id='kt_app_header_wrapper'
          className='d-flex justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >

                <img width={190} src={toAbsoluteUrl('/media/icons/sidebar_icons/Logo.svg')} alt=""/>
                <SearchBar
                  searchText={searchText}
                  setSearchText={setSearchText}
                  isPhone={false}
                />
                  {/* <div className='' style={{display:'flex', alignItems: 'center', justifyContent: 'center', width: '36vw', marginLeft: 43}}>

                  </div> */}
              </div>
            )}
            <div
              style={{alignItems: 'center', display: 'flex', justifyContent: 'center', width: '4%'}}
              onClick={() => {
                setType('tag');
                $("#kt_help_toggle").click();
              }}
            >
              <img src={toAbsoluteUrl('/media/icons/sidebar_icons/stopbar_open_icon.png')} width={'45%'} />
            </div>
          {/* <Navbar /> */}
        </div>
      </div>
    </div>
  )
}
