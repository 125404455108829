/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";

import '../../../assets/style/styles.css';
import { styles } from "../../../assets/style/styles";
import { ArrowLeft } from "react-bootstrap-icons";

const TermsOfUse = ({
    setType,
}) => {

    return (
        <div className='card shadow-none rounded-0 w-100' style={{...styles.helpDrawerStyle.cardContainer, padding: 24, height: 1100}}>
            <ArrowLeft onClick={() => setType('newLocation')} />
            <p className="tag-header-text mt-5">Platform Amacı:</p>
            <p className="tag-text">
                Bu platform, Şubat 2023 depremleri sonrasında Hatay ilindeki depremzedelerin anılarını harita üzerinde toplamayı amaçlamaktadır. Kullanıcılar, Google Map tabanlı harita üzerinden bir lokasyon seçerek hatıra oluşturabilir ve bu hatıraları diğer kullanıcılarla paylaşabilirler.
            </p>
            <p className="tag-header-text">Kullanım Koşulları:</p>
                <p className="tag-header-text" style={{marginInline: 12}}>Platformun Kullanımı:</p>
                    <ul style={{marginInline: 12}}>
                        <li className="tag-text">Bu sitede yer alan hizmetler NEHNA platformu tarafından sağlanmakta olup, NEHNA sitenin kullanımına ilişkin her türlü tasarruf ve kullanım yetkisine haizdir.</li>
                        <li className="tag-text">Bu sözleşme, platformu kullanan kullanıcılarımızın yasal haklarını ve sorumluluklarını düzenleyen bir sözleşmedir.</li>
                        <li className="tag-text">Platformu kullanarak, bu koşulları kabul etmiş sayılırsınız.</li>
                    </ul>
                <p className="tag-header-text">Hatıra Oluşturma ve Paylaşma:</p>
                    <ul style={{marginInline: 12}}>
                        <li className="tag-text">Hatıra oluştururken sağlanan bilgilerin doğruluğu ve içeriğin platform kurallarına uygunluğu kullanıcının sorumluluğundadır.</li>
                        <li className="tag-text">Moderasyon süreci, oluşturulan hatıraları inceler ve uygun bulunanları platformda yayınlar. Bu konuda takdir hakkı</li>
                    </ul>
                <p className="tag-header-text">İçerik Kullanımı ve Paylaşımı:</p>
                    <ul style={{marginInline: 12}}>
                        <li className="tag-text">Platform üzerinde paylaşılan hatıralar, platform tarafından belirlenen kurallara uygun olarak kullanılabilir.</li>
                        <li className="tag-text">Kullanıcılar iş bu sözleşmeyi onaylayarak, yüklemiş oldukları verilerin
                            kişisel veri olması halinde dahi NEHNA tarafından söz konusu sitede
                            kullanılmasına, yayınlamasına ve üçüncü kişiler tarafından
                            ulaşılabilmesine muvafakat etmektedirler.</li>
                        <li className="tag-text">Başkalarının hatıralarını kullanma veya paylaşma hakkında belirli kısıtlamalar vardır.</li>
                    </ul>
            <p className="tag-header-text">Gizlilik Politikası:</p>
                <p className="tag-header-text">Çerezler ve İzleme:</p>
                    <ul style={{marginInline: 12}}>
                        <li className="tag-text">Platform, çerezler ve benzer izleme teknolojilerini kullanabilir. Kullanıcılar bu konuda tercihlerini belirleyebilir ve yönetebilir.</li>
                    </ul>
                <p className="tag-header-text">Güvenlik:</p>
                    <ul style={{marginInline: 12}}>
                        <li className="tag-text">Kullanıcı bilgileri, güvenlik önlemleri alınarak korunur. Ancak, internet üzerinden iletişim her zaman risk taşır ve bu konuda tam güvenlik sağlanamaz.</li>
                    </ul>
                <p className="tag-header-text">Üçüncü Taraf Hizmetleri:</p>
                    <ul style={{marginInline: 12}}>
                        <li className="tag-text">Kullanıcı bilgileri, yasal gereklilikler haricinde asla üçüncü taraflarla paylaşılmaz.</li>
                    </ul>
                <p className="tag-header-text">Yasal Yükümlülükler:</p>
                    <ul style={{marginInline: 12}}>
                        <li className="tag-text">Platform, yerel ve genel veri koruma yasalarına uymak için gereken adımları atar ve kullanıcı bilgileriyle ilgili yasal taleplere cevap verir.</li>
                    </ul>
        </div>
    )
}

export {TermsOfUse}