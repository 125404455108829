/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import '../../../assets/style/styles.css';
import { styles } from "../../../assets/style/styles";
import axios from "axios";
import { KTSVG } from "../../../helpers";

const MemoryDetail = ({
    id,
    memory,
    setType,
    previousType,
    modalData,
    setMemories,
    setPreviousType,
    fromStaticLocation,
}) => {
    useEffect(() => {
        if(modalData){
            !fromStaticLocation && (
                axios.get(`${process.env.REACT_APP_API_URL}/data/memories?typ=savedLocation&parent_id=${modalData.parent_id}`).then(res => {
                    setMemories(res?.data);
                }).catch(err => console.log(err))
            )
        }
    }, [modalData]);

    return (
        <div className='card shadow-none rounded-0 w-100' style={{...styles.helpDrawerStyle.cardContainer, padding: 24}}>
            {/* Header */}
            <div className='custom-card-header mt-5' id='kt_help_header_memory'>
                <div className="col-12" style={{flexDirection: 'column'}}>
                    <div className="d-flex" style={{display: 'flex', justifyContent: fromStaticLocation ? 'space-between' : 'flex-end'}}>
                        {fromStaticLocation && <p className='header-address-text'>{modalData?.name}</p>}
                        {fromStaticLocation && (
                            <>
                            <div
                                className="p-1"
                                onClick={() => {
                                    setType(previousType);
                                }}
                            >
                                <ArrowLeft width={12} color="#766667" />
                            </div>
                             {/* <p className='header-name-text mt-5'>{memory?.name}</p>
                             <p className="" style={{fontSize: 16, fontWeight: 400, color: '#AFA8A9'}}>{memory?.date}</p> */}
                             </>
                        )}

                        {!fromStaticLocation && (
                            <div style={{display: 'flex',  justifyContent: 'space-between', width: '100%'}}>
                                 <p className='header-name-text mt-5'>{memory?.name}</p>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-icon explore-btn-dismiss mt-3'
                                    id='kt_help_close'
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2'/>
                                </button>


                            </div>

                        )}

                    </div>
                    {!fromStaticLocation && (<p className="" style={{fontSize: 16, fontWeight: 400, color: '#AFA8A9'}}>{memory?.date}</p>)}

                    {fromStaticLocation && (
                        <>
                        <p className='header-name-text mt-5'>{memory?.name}</p>
                        <p className="" style={{fontSize: 16, fontWeight: 400, color: '#AFA8A9'}}>{memory?.date}</p>
                        </>
                    )}

                </div>
            </div>
            {/* Body */}
            {memory && (
                <>
                    <div style={{height: '92%'}}>
                        <div className='col-12 mt-4 justify-content-center'>
                            <img height={'100%'}  width={'100%'} src={memory.path[0]} style={{borderRadius: '8px', objectFit: 'cover'}}/>
                        </div>
                        <div className='col-12 mt-4 mb-5 justify-content-center'>
                            <p className="memory-detail-text" style={{width: '100%'}}>{memory?.detail}</p>
                        </div>
                        <p className="" style={{...styles.StaticLocationStyle.addressText, marginLeft: 1}}>{memory?.address}</p>
                    </div>

                </>
            )}
        </div>
    )
}

export {MemoryDetail}