/* eslint-disable jsx-a11y/alt-text */
import React, {useState} from "react";
import { styles } from "../../../assets/style/styles";
import '../../../assets/style/styles.css';
import { toAbsoluteUrl } from "../../../helpers";
import { Envelope } from "react-bootstrap-icons";


const Tag = () => {
    const [collapse, setCollapse] = useState(true);

    return (
        <div className='card shadow-none rounded-0 w-100' style={styles.helpDrawerStyle.cardContainer}>
           {/* Header */}
            <div className='custom-card-header' style={{marginInline: 26, marginTop:18}} id='kt_help_header_tag'>
                <img src={toAbsoluteUrl('/media/icons/sidebar_icons/Logo.svg')}  />
            </div>
            <div className="card-body" style={{height: '92%'}}>
                <p className="tag-text">
                    Beledna - Hafıza Haritası, katılımcı çevrimiçi bir hafıza mekanı. Duyguların, anıların ve öykülerin
                    birbirine dokunduğu bir dijital dünya. Bu dünya, Hataylıların yanı sıra bu şehre adım atmış herkesin
                    hatıralarını bir araya getirerek belednanın, yani memleketimizin canlı hafızasını oluşturmayı hedefliyor.
                </p>
                <p className="tag-text">
                    Burada amacımız, şehirle bir şekilde bağ kuranların katkı sunduğu bir arşiv oluşturarak, şehrin yaşadığı
                    yıkım ve yeniden inşanın yarattığı mekansal hafıza kaybına bir nebze de olsa engel olmak. Kullanıcılar, harita
                    üzerinde dolaşırken diledikleri yeri işaretleyecek, o yerlere yazılar ve görseller ekleyerek kişisel hikayelerini paylaşacaklar.
                </p>
                <p className="tag-text">
                    Böylece oluşacak bu benzersiz arşiv, Hatay'ın sadece bir felaket coğrafyası değil, aynı zamanda bir dizi yaşanmışlıkla, biraradalıkla,
                    farklılıkla ve mücadeleyle var olmaya devam eden bir mekan olduğunu, bu şehre dair anlatacakları olanlardan dinleyeceğimiz bir hikayeye dönüşecek.
                </p>
                <p className="tag-text">
                    Herhangi bir sorunuz veya öneriniz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.
                </p>
                <p className="tag-text">Teşekkürler!</p>

                <div className="col-3" style={{border: '1px solid', height: 48, borderRadius: 4, borderColor: '#503F40'}}>
                    <a
                        className="d-flex m-4"
                        style={{flexDirection: 'row',
                        justifyContent: 'space-evenly'}}
                        href='mailto:info@nehna.org'
                    >
                        <div><Envelope size={18} color="#503F40" /></div>
                        <p className="contact-text mt-1">İletişim</p>
                    </a>
                </div>
                <div style={{border: '0.5px solid #2F1A1F3D', marginTop: 24}}></div>
                <div
                    onClick={() => setCollapse(previous => !previous)}
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 12}}
                >
                    {/* <p className="tag-text">Künye</p>
                    {collapse
                        ? <img src={toAbsoluteUrl('/media/icons/arrow-up.svg')} />
                        : <img src={toAbsoluteUrl('/media/icons/arrow-down.svg')} />
                    } */}
                </div>
                {/* height: collapse ? 220: 0 */}
                <div className="mt-1 tag" style={{height: 540}}>
                    <p className="tag-header-text">Proje Geliştirme</p>
                    <p className="tag-text">Gözde Aydın, Nurbanu Hüsrevoğlu, Anıl B. Poyrazoğlu</p>
                    <p className="tag-header-text">Tasarım</p>
                    <p className="tag-text">Tolga Kütükçü</p>
                    <p className="tag-header-text">Referans</p>
                    <p className="tag-text">Hazal Doğruel - Kübra Güleryüz - Türkan Hilal İpek - Danışman: Tuğçe Tezer</p>
                    <p className="tag-header-text">Moderasyon</p>
                    <p className="tag-text">Rana Aytar - Burçin Ertokuş</p>
                    <p className="tag-header-text">Teşekkürler</p>
                    <p className="tag-text">Nehna Ailesi</p>
                    <p className="tag-text">Esril Bayrak</p>
                    <p className="tag-text">Rafael Coelho</p>
                    <p className="tag-text">Jaklin Guner</p>
                    <p className="tag-text">Çiğdem Mater</p>
                    <p className="tag-text">Tamar Nalcı</p>
                </div>
            </div>
        </div>
    )
}

export {Tag}