/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const MemoryCard = ({
    memory,
    setMemoryId,
    setType,
    setPreviousType,
}) => {
    const onClick = () => {
        setMemoryId(memory.id);
        setTimeout(() => {
            setType((previous) => {
                setPreviousType(previous);
            });
            setType('memoryDetail');
        }, 250)
    }

    return(
        <div
            className=""
            style={{width: '48%', borderRadius: 4, marginBottom: 12}}
            onClick={onClick}
        >
            <img style={{borderRadius: 4, objectFit: 'cover'}} width={'100%'} height={150} src={memory.path[0]} alt=""></img>
            <div className="p-2 mt-2">
                <div className="col-12">
                    <span className="memory-card-text-name">{memory.name}</span>
                </div>
                <div className="col-12 mt-1">
                    <span className="memory-card-text-date">{memory.date}</span>
                </div>
            </div>
        </div>
    )
}

export {MemoryCard}