/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useRef, memo, useEffect} from 'react'
import {KTSVG} from '../../../helpers'
// @ts-ignore
import DatePicker, {setDefaultLocale, registerLocale} from "react-datepicker";
import tr from 'date-fns/locale/tr';
import "react-datepicker/dist/react-datepicker.css";
import {
    GoogleReCaptcha, GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import axios from "axios";
import {data} from "jquery";
import {Form} from "react-bootstrap"
import AWS from "aws-sdk"
import {styles} from '../../../assets/style/styles';
import '../../../assets/style/styles.css';
import {BiCalendarEvent} from "react-icons/bi";
import { toAbsoluteUrl } from '../../../helpers';
import { CustomButton } from '../../../layout/components/custom-button/custom-buttom';
import { StaticLocation } from '../../../layout/components/static-location/staticLocation';
import { SavedLocation } from '../../../layout/components/saved-location/savedLocation';
import { NewLocation } from '../../../layout/components/new-location/newLocation';
import { MemoryDetail } from '../../../layout/components/memory-detail/memoryDetail';
import { Tag } from '../../../layout/components/tag/tag';
import { TermsOfUse } from '../../../layout/components/terms-of-use/termsOfUse';
import { UploadSuccess } from '../../../layout/components/upload-success/uploadSuccess';
registerLocale('tr', tr)


// @ts-ignore
const HelpDrawer = ({
    userData, setUserData, saveData, error,
    modalData, type, setType, address, memories,
    setMemories, setPreviousType, previousType,
    newPosition, setPosition, fromStaticLocation,
    memoryId, setMemoryId, nameInputRef, handleNameInputChange, setError
}:any) => {
    const [loader, setLoader] = useState(false);

    return (
        <div
            id='kt_help'
            className='bg-body loader-background-color'
            data-kt-drawer='true'
            data-kt-drawer-name='help'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'350px', 'md': '525px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_help_toggle'
            data-kt-drawer-close='#kt_help_close'

        >
            {!loader ? (
                <>
                    {/* <div className='col-12' style={{border: '1px solid red'}}>
                        <button onClick={() => setMemoryId('2')}>asadasdasda</button>
                    </div> */}
                    {/* begin::Card */}
                    {type === 'staticLocation' && (
                        <StaticLocation
                            modalData={modalData}
                            userData={userData}
                            setType={setType}
                            address={address}
                            setUserData={setUserData}
                            // @ts-ignore
                            memories={memories}
                            setMemories={setMemories}
                            setMemoryId={setMemoryId}
                            // @ts-ignore
                            setPreviousType={setPreviousType}
                            previousType={previousType}
                        />
                    )}

                    {type === 'savedLocation' && (
                        <SavedLocation
                            userData={userData}
                            modalData={modalData}
                            setUserData={setUserData}
                            setType={setType}
                            address={address}
                            // @ts-ignore
                            memories={memories}
                            setMemories={setMemories}
                            setMemoryId={setMemoryId}
                            memoryId={memoryId}
                            // @ts-ignore
                            setPreviousType={setPreviousType}
                            previousType={previousType}
                        />
                    )}
                    {type === 'newLocation' && (
                        <NewLocation
                            userData={userData}
                            setUserData={setUserData}
                            error={error}
                            DatePicker={DatePicker}
                            setLoader={setLoader}
                            setError={setError}
                            saveData={saveData}
                            modalData={modalData}
                            address={address}
                            // @ts-ignore
                            setPreviousType={setPreviousType}
                            previousType={previousType}
                            newPosition={newPosition}
                            setPosition={setPosition}
                            setType={setType}
                            nameInputRef={nameInputRef}
                            // handleNameInputChange={handleNameInputChange}
                        />
                    )}
                    {type === 'memoryDetail' && (
                        // @ts-ignore
                        <MemoryDetail
                            memory={memories.find((item : any) => item?.id === memoryId)}
                            previousType={previousType}
                            // @ts-ignore
                            setType={setType}
                            modalData={modalData}
                            setMemories={setMemories}
                            setPreviousType={setPreviousType}
                            fromStaticLocation={fromStaticLocation}
                        />
                    )}
                    {type === 'tag' && (
                        <Tag />
                    )}
                    {type === 'termsOfUse' && (
                        <TermsOfUse
                            setType={setType}
                        />
                    )}
                    {type === 'uploadSuccess' && (
                        <UploadSuccess
                            setPosition={setPosition}
                        />
                    )}
                    {/* end::Card */}
                </>
            )
            :
            (
                <div>
                    <div className='loader'></div>
                    <a className='loader-text'>Hatıranız yükleniyor...</a>
                </div>
            )
        }
        </div>
    )
}

export {HelpDrawer}
