/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";

import '../../../assets/style/styles.css';
import { styles } from "../../../assets/style/styles";
import { toAbsoluteUrl } from "../../../helpers";
import $ from 'jquery';

const UploadSuccess = ({
    setPosition,
}:any) => {

    return (
        <div className='card shadow-none rounded-0 w-100' style={{...styles.helpDrawerStyle.cardContainer, padding: 24, height: 700}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '60%'}}>
                <img src={toAbsoluteUrl('/media/icons/success.svg')}></img>
                <p className="mt-4 success-text">Teşekkürler</p>
                <p className="success-explanation-text" style={{textAlign: 'center'}}>Şehirlerimizin hatıralarda yaşamasına destek olduğun için teşekkürler. Hatıranız en kısa süre içerisinde incelenip haritaya eklenecektir.</p>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 52}}>
                    <div
                        id='close-box'
                        style={{border: '1px solid #503F40',display: 'flex', alignItems: 'center', justifyContent: 'center', width: 112, height: '100%', borderRadius: 6}}
                        onClick={() => {
                            $("#kt_help_toggle").click()
                            setPosition(undefined);
                            window.location.reload()
                        }}
                    >
                        <p className="success-explanation-text mt-3">Kapat</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {UploadSuccess}