/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect} from "react";
import  {KTSVG, toAbsoluteUrl} from '../../../helpers';
import '../../../assets/style/styles';
import { styles } from "../../../assets/style/styles";
import { CustomButton } from "../custom-button/custom-buttom";
import { MemoryCard } from "../memory-card/memoryCard";
import axios from "axios";


const SavedLocation = ({
    userData,
    setType,
    modalData,
    setUserData,
    address,
    memories,
    setMemories,
    setMemoryId,
    memoryId,
    setPreviousType,
    previousType,
}) => {
    useEffect(() => {
        if(modalData){
            axios.get(`${process.env.REACT_APP_API_URL}/data/memories?typ=savedLocation&parent_id=${modalData.parent_id}`).then(res => {
                setMemories(res?.data);
            }).catch(err => console.log(err))
        }
    }, [modalData]);
    return (
        <div className='card shadow-none rounded-0 w-100' style={styles.helpDrawerStyle.cardContainer}>
            <div className='custom-card-header mb-3' id='kt_help_header_saved'>
                <img src={toAbsoluteUrl('/media/icons/Pin_General.svg')} />
                <a className='header-address-text mt-3'>{address}</a>
                <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon explore-btn-dismiss mt-3'
                        id='kt_help_close'
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2'/>
                    </button>
                </div>
            </div>
            <div className='col-12 memory-wrapper mt-5' style={{height:'82%', paddingInline: 24}}>
                {memories.length > 0 && (
                    memories.map(item => {
                        return(
                            <MemoryCard
                                key={item.id}
                                memory={item}
                                setMemoryId={setMemoryId}
                                setType={setType}
                                setPreviousType={setPreviousType}
                            />
                        )
                    })
                )}
            </div>
            <div style={{marginInline: 26}}>
                <CustomButton
                    buttonTitle={'Hatıra Ekle'}
                    onClick={() => {
                        setType((previous) => {
                            setPreviousType(previous);
                        });
                        setUserData({...userData, address: modalData.address, typ: 'savedLocation', parent_id: modalData.id})
                        setType('newLocation');

                    }}
                />
            </div>
        </div>
    )
}

export {SavedLocation}
