/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { string } from "yup";
import "../../../assets/style/styles";

const CustomButton = (
    {
        buttonTitle,
        onClick = () => {},
        buttonDisabled,
    }: any
) => {
    const emptyFunction = () => {}
    return (
        <div className="mb-10 col-12">
            <div className="form-check form-check-custom form-check-solid" style={{backgroundColor: buttonDisabled ? '#F4EEEA' : '#503F40'}}>
                <div className='col-12 custom-button' onClick={!buttonDisabled ? onClick : emptyFunction}>
                    <a className='buttonText'>{buttonTitle}</a>
                </div>
            </div>
            {/* <button
                className='hidden'
                ref={ref}
                type='button'
                onClick={handleSave}
            >
            </button> */}
        </div>
    )
}

export {CustomButton}