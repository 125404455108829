import React, {useEffect, useState, useCallback, useRef} from "react";
import {ToggleHelpDrawer} from "../../../_metronic/partials/layout/help-drawer/ToggleHelpDrawer";
import {HelpDrawer} from "../../../_metronic/partials/layout/help-drawer/HelpDrawer";

import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {Spinner} from "react-bootstrap";
import $ from 'jquery';
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import axios from "axios";
import { HeaderWrapper } from "../../../_metronic/layout/components/header";

const containerStyle = {
    height: '100vh', width: '100%'
};

const center = {
    lat: 36.200013582257114,
    lng: 36.161022113651725
};
const libraries = ["places"]


const MapPage = () => {
    const nameInputRef = useRef();
    const [searchText, setSearchText] = useState("");
    useEffect(() => {
        getUserData()
        getFilteredLocData(searchText);
    }, [searchText])
    const [type, setType] = useState("");
    const [previousType, setPreviousType] = useState("");
    const [locationLoad, setLocationLoad] = useState(false);
    const [error, setError] = useState('')
    const [address, setAddress] = useState();
    const [locations, setLocations] = useState([])
    // const [checkLocations, setCheckLocations] = useState({})
    const [locationData, setLocationData] = useState([])
    const [modalData, setModalData] = useState({})
    const [position, setPosition] = useState()
    const [savedPosition, setSavedPosition] = useState([])
    const [savedPositionData, setSavedPositionData] = useState([])
    const [memories, setMemories] = useState([]);
    const [fromStaticLocation, setFromStaticLocation] = useState(false);
    const [memoryId, setMemoryId] = useState("");
    const [userData, setUserData] = useState({
        accept: false,
        show: false,
        created_at: " ",
        date: " ",
        detail: " ",
        address: "",
        latitude: 39.972,
        longitude: 32.853,
        name: " ",
        parent_id: null,
        path: [" "]
    })
    const {isLoaded} = useJsApiLoader({
        mapIds: ['8ead7022e643742f'],
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
        // @ts-ignore
        libraries: libraries
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
    }, [])

    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])

    const handleOnClick = async (e) => {
        setPreviousType('newLocation');
        setType('newLocation');
        setError('')
        setPosition({lat: e.latLng.lat(), lng: e.latLng.lng()})
        $("#kt_help_toggle").click()
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({
            location: {lat: e.latLng.lat(), lng: e.latLng.lng()}
        });
        const created_at = new Date()
        setAddress(response.results[1].formatted_address);
        setUserData({
            ...userData,
            address: response.results[1].formatted_address,
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
            created_at: `${created_at.getDate()}/${created_at.getMonth() + 1}/${created_at.getFullYear()}`
        })
    }

    const handleLocationClick = async (e) => {
        setType('staticLocation');
        setFromStaticLocation(true);
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        const tmpData = locationData.find(item => (item.lat === lat && item.long === lng))
        setModalData({
            ...tmpData,
            parent_id: locationData.id,
            address: tmpData.address,

        })
        setAddress(tmpData.address);

        // setIsStaticLocation(true);
        $("#kt_help_toggle").click();
        // handleShow()
    }

    const handleSavedPositionClick = async (e) => {
        setFromStaticLocation(false);
        setType('memoryDetail');
        const lat = e.latLng.lat()
        const lng = e.latLng.lng()
        const tmpData = savedPositionData.find(item => (item.latitude === lat && item.longitude === lng))
        setMemoryId(tmpData?.id);
        setModalData(prevState => {
            return {
                ...tmpData,
                description: tmpData.detail,
                photo: tmpData.path,
                parent_id: tmpData.id
            }
        })
        setAddress(tmpData.address);
        // setIsStaticLocation(false);
        $("#kt_help_toggle").click();
        // handleShow()

    }

    const saveData = async (name, detail) => {
        try {
            setUserData({...userData, typ: previousType});
            if (name && userData.date.trim() !== '' && detail && userData.path[0].trim() !== '' && userData.accept) {
                const rsp = await axios.get(userData.path[0].trim())
                let id = userData?.id ? userData?.id : null
                axios.post(`${process.env.REACT_APP_API_URL}/data/memories`, {...userData, name: name, detail: detail, typ: previousType}).then(res => {
                    setType('uploadSuccess');
                    setPosition(undefined)
                    getUserData()
                    setUserData({
                        accept: false,
                        show: false,
                        created_at: " ",
                        date: " ",
                        detail: " ",
                        address: "",
                        latitude: 39.972,
                        longitude: 32.853,
                        name: " ",
                        parent_id: null,
                        path: [" "],
                        typ: ""
                    })
                }).catch(err => console.log(err))
            } else {
                setError('Bilgileri Eksiksiz Doldurup Kullanım Koşullarını kabul edin lütfen')
                return;
            }
        } catch (e) {
            setError('Fotoğraf eklerken bir sorun oluştu. Lütfen tekrar deneyin')
            console.error("Error adding document: ", e);
        }
        // $("#kt_help_toggle").click()

    }

    const getUserData = async () => {
        axios.get(`${process.env.REACT_APP_API_URL}/data/memories?typ=${type}`).then(res => {

            res.data.map((item) => {
                // @ts-ignore
                setSavedPosition(prevState => ([...prevState, {lat: item.latitude, lng: item.longitude}]))
                // @ts-ignore
                setSavedPositionData(prevState => ([...prevState, item]))
            })
        }).catch(err => console.log(err))
    }
    const getFilteredLocData = async () => {
        setLocationLoad(false)
        axios.post(`${process.env.REACT_APP_API_URL}/data/location`, {text: searchText}).then(res => {
            setLocations(res.data.map(item => ({lat: item.lat, lng: item.long})))
            setLocationData(res.data)
            setLocationLoad(true)
        }).catch(err => {
            console.log('error: ', err)
            setLocationLoad(true)
        })
    }

    // @ts-ignore
    return (
        <>
        <HeaderWrapper
            setType={setType}
            searchText={searchText}
            setSearchText={setSearchText}
        />
            {/* <LocationModal show={show} handleClose={handleClose} data={modalData}/> */}
            {
                (isLoaded && locations.length >= 0) ? (
                    <GoogleMap
                        options={{mapId: '8ead7022e643742f'}}
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={16}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onClick={handleOnClick}
                    >
                        {locationLoad && locations.map(item => {
                            return (
                                <Marker
                                    icon={toAbsoluteUrl('/media/icons/Pin_Castle.svg')}
                                    position={item}
                                    onClick={handleLocationClick}
                                    key={Math.random()}
                                />
                            )
                        })
                        }
                        {position &&
                            <Marker
                                icon={toAbsoluteUrl('/media/icons/Pin_General.svg')}
                                // @ts-ignore
                                position={position}
                                clickable={true}
                                draggable={true}
                                onClick={handleOnClick}
                                onDragEnd={handleOnClick}
                                key={Math.random()}
                            />
                        }
                        {savedPosition && savedPosition.map(item => {
                            return (
                                <Marker
                                    icon={toAbsoluteUrl('/media/icons/Pin_General.svg')}
                                    // @ts-ignore
                                    position={item}
                                    onClick={handleSavedPositionClick}
                                    key={Math.random()}
                                />
                            )
                        })
                        }
                    </GoogleMap>
                ) : <Spinner animation='border'/>
            }
            <div
                className='transform-90' style={{borderWidth: 1}}>
                <ToggleHelpDrawer/>
            </div>
            <HelpDrawer
                setError={setError}
                userData={userData}
                setUserData={setUserData}
                saveData={saveData}
                error={error}
                modalData={modalData}
                type={type}
                setType={setType}
                address={address}
                memories={memories}
                setMemories={setMemories}
                setPreviousType={setPreviousType}
                previousType={previousType}
                newPosition={position}
                setPosition={setPosition}
                fromStaticLocation={fromStaticLocation}
                setMemoryId={setMemoryId}
                memoryId={memoryId}
                nameInputRef={nameInputRef}
                // handleNameInputChange={handleNameInputChange}
            />
        </>
    )
}

export {MapPage}
